<template>
    <div class="header">
        <el-header>
            <div class="title">电力试验装备智能管理系统</div>

            <div class="df2">
                <!-- <span class="light"><i class="fa fa-bell-o" title="未读消息"><span class="info"><i>1</i></span></i></span>
                <span class="light"><i class="fa fa-envelope-o" title="待处理消息"><span class="info"><i>99+</i></span></i></span> -->
                <span class="timestate">{{ getDateTimeState() }}</span>

                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                        <span>{{ userInfo.realName }}</span>
                        <i class="el-icon-user"></i>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="updatePass">修改密码</el-dropdown-item>
                        <el-dropdown-item @click.native="updateUserInfo">个人信息</el-dropdown-item>

                        <el-dropdown-item @click.native="handleLogOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>

            <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="500px" :close-on-click-modal="false" :before-close="closeInfo">
                <el-form :model="form" :rules="rules" ref="form">
                    <el-form-item label="新密码" :label-width="formLabelWidth" prop="password">
                        <el-input v-model="form.password" type="password" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" :label-width="formLabelWidth" prop="confirmPass">
                        <el-input v-model="form.confirmPass" type="password" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeInfo('form')">取 消</el-button>
                    <el-button class="btn" @click="sure('form')">确 定</el-button>
                </div>
            </el-dialog>

        </el-header>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import { getToken } from '@/utils/setToken.js';
import { logout } from '@/api/login.js'
import { resetUserPass} from '@/api/user'
import { passRule } from '@/utils/vaildate.js'
export default {
    data() {
        const pwdAginCheck = async (rule, value, callback) => {
            if (value.length < 1) {
                callback(new Error('重复密码不能为空！'))
            } else if (this.form.password !== this.form.confirmPass) {
                callback(new Error('两次输入密码不一致！'))
            } else {
                callback()
            }
        }
        return {
            roleList: [],
            userInfo: {},
            dialogFormVisible: false,
            dialogFormView: false,
            form: {
                account: '',
                password: '',
                confirmPass: ''
            },
            formLabelWidth: "80px",
            rules: {
                password: [{ required: true, validator: passRule, trigger: 'blur' }],
                confirmPass: [{ required: true, validator: pwdAginCheck, trigger: 'blur' }],
            }
        }
    },
    computed: {
        collapseIcon() {
            return this.$store.state.isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'
        }
    },
    mounted() {
        this.userInfo = window.localStorage.getItem('userInfo') ? JSON.parse(getToken('userInfo')) : {};
    },
    methods: {
        // 退出登录
        handleLogOut() {
            this.$confirm('确认退出?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                logout().then(res => {
                    if (res.code === '000000') {
                        this.$message({
                            type: 'success',
                            message: '退出成功!'
                        })
                        Cookies.remove('token')
                        window.localStorage.clear()
                        // this.$router.push({
                        // name: 'Login'
                        // })
                        this.$router.push('/login')
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消退出'
                })
            })
        },
        // 修改密码
        updatePass() {
            this.form = {
                account: this.userInfo.account,
                password: '',
                confirmPass: ''
            },
                this.dialogFormVisible = true
        },
        //关闭
        closeInfo() {
            this.$refs['form'].resetFields();
            this.dialogFormVisible = false
        },
        //提交
        sure(from) {
            this.$refs[from].validate(valid => {
                if (valid) {
                    resetUserPass(this.form.account, this.form.password).then(res => {
                        if (res.code === '000000') {
                            this.$message.success('修改密码成功,请重新登录！')
                            setTimeout(() => {
                                window.localStorage.clear()
                                this.$router.replace('/login')
                            }, 1500)
                        }
                        this.dialogFormVisible = false
                        this.$refs[from].resetFields()

                    })

                }
            })

        },

        updateUserInfo() {
            this.$router.push({
                name: 'home-userInfo',
            })
        },
        
        //获取当前时间
        getDateTimeState() {
            //获取当前小时
            var hours = new Date().getHours();
            //设置默认文字
            let state = '';
            //判断当前时间
            if (hours >= 0 && hours <= 10) {
                state = '早上好！';
            } else if (hours > 10 && hours <= 14) {
                state = '中午好！';
            } else if (hours > 14 && hours <= 18) {
                state = '下午好！';
            } else if (hours > 18 && hours <= 24) {
                state = '晚上好！'
            }

            return state;
        }


    }

}
</script>

<style lang="scss">
.header {
    .el-header {
        // #476DF2
        // background-color: #142354;
        background-color: rgb(0, 101, 105);
        color: #d6d7da;
        line-height: 60px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #374165;

        .light {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 24px;
            line-height: 24px;
            // border-right: 1px solid #D8E0FB;
            color: #D8E0FB;

            .info {
                position: absolute;
                top: -6px;
                left: 33px;
                width: 14px;
                height: 14px;
                text-align: center;
                line-height: 12px;
                color: #D8E0FB;
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
                background-color: #f00;

                i {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    font-size: 12px;
                    font-weight: 700;
                    transform: scale(0.7);
                }
            }

        }

        .light:hover {
            cursor: pointer;
            color: #7485bb;
        }

        .timestate {
            color: #D8E0FB;
            margin-left: 10px;

        }

        .el-dropdown-link {
            color: #D8E0FB;
        }

        .el-dropdown-link:hover {
            cursor: pointer;
            color: #7485bb;
        }

        // .df2 .el-dropdown-menu {
        //         background: #374165;
        // }

        .title {
            width: 300px;
            font-size: 24px;
        }

        .el-form-item__content {
            text-align: left;
        }

    }
}

.df2 .el-dropdown-menu {
    // background-color: #142354;
    background-color: rgb(0, 101, 105);
}
</style>