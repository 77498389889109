<template>
  <div class="menu">
    <el-aside width="240px">
      <el-scrollbar class="myscrollbar">
        <!-- #68B2FF background-color="#142354"  7485bb  #ffd04b  -->
        <el-menu router :default-active="routePath" class="el-menu-vertical-demo" background-color="rgb(0,101,105)"
          text-color="#fcfcfd" active-text-color="#ffd04b">

          <template v-for="(item, index) in moduleVoList">
            <el-submenu :index="index + ''" :key="index">
              <template slot="title">
                <div class="modul">
                  <i :class="item.icon"></i>
                  <span>{{ item.moduleName }}</span>
                </div>
              </template>
              <el-menu-item-group v-for="(child, index) in item.functionVoList" :key="index">
                <el-menu-item :index="child.funcUrl">
                  <!-- <i :class="child.iconClass"></i> -->
                  <div class="modul">
                    <i :class="child.icon"></i>
                    {{ child.funcName }}
                  </div>

                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </template>
        </el-menu>
      </el-scrollbar>
    </el-aside>
  </div>
</template>

<script>
import { getToken } from '@/utils/setToken.js'
// import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      moduleVoList: [],
      routePath: ''
    };
  },
  created() {
    this.moduleVoList = JSON.parse(getToken("moduleVoList"))
  },
  mounted() {
    let paths = this.$route.path.split('/', 3);
    this.routePath = '/' + paths[1] + '/' + paths[2]
  },
};
</script>

<style lang="scss">
.menu {
  .el-aside {
    height: 100%;
  }

  .myscrollbar {
    height: 100%;
    width: 100%;
    // background-color: #142354;
    background-color: rgb(0, 101, 105);
  }

  .myscrollbar .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .el-menu {
    height: 100%;
    border-right: none;

    .fa {
      margin-right: 10px;
    }
  }

  .el-submenu .el-menu-item {
    min-width: 0;
  }

  .el-submenu i{
    color: #fcfcfd;
  }

  .el-submenu .el-menu-item:hover{
    background-color: rgb(1, 52, 53) !important;
  }
  .el-submenu .el-submenu__title:hover {
    background-color: rgb(1, 52, 53) !important;
  }

  .el-menu-item-group__title {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .modul {
    text-align: left;
  }

}</style>