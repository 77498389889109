<template>
    <div class="crumb" v-if="routes.length > 0">
        <el-card>
            <!-- <div class="home" v-if="routes.length === 0">
                <span>首页</span>
            </div> -->
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="routes.length > 0">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item, index) in routes" :key="index">{{ item.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
            <!-- <el-breadcrumb separator="/" v-if="routes.length > 0">
                <el-breadcrumb-item v-for="(item, index) in routes" :key="index" :class="index === routes.length - 1 ? 'last' : ''"
                    :to="index > 0 && routes.length >= 3 && index != routes.length - 1 ? { path: item.path } : ''">

                    {{ item.meta.name }}
                </el-breadcrumb-item>
            </el-breadcrumb> -->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            routes: []
        }
    },
    watch: {
        $route: function () {
            this.getRoute()
        }
    },
    mounted() {
        window.addEventListener('load', () => {
            // 写入你想要执行的代码
            this.getRoute()
        })
    },
    methods: {
        // 获取当前路由信息
        getRoute() {
            this.routes = this.$route.matched.filter(item => item.meta.title)
            console.log('this.routes')
            console.log(this.routes)
        }
    }
}
</script>
<style lang="scss">
.crumb {
    margin-bottom: 20px;
    .home {
        font-weight: 700 !important;
        font-size: 14px;
        text-align: left;
    }
}
</style>

