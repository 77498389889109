<template>
    <el-card class="foot">
        电力试验装备智能管理系统©2023
    </el-card>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>

<style lang="scss">
    .foot {
        font-size: 13px;
        color: #222;
        background-color: rgba(249,250,251, 0.338);
    }
</style>
