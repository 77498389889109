<template>
  <div class="home">
    <Header/>
    <el-container class="content">
      <Menu/>
      <el-container>
        <el-main>
          <Bread/>
          <div class="cont">
            <router-view/>
          </div>
        </el-main>
        <el-footer><Footer/></el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from './commom/Header.vue'
import Footer from './commom/Footer.vue'
import Menu from './commom/Menu.vue'
import Bread from './commom/Breadcrumb.vue'
export default {
  components: {
    Header,
    Footer,
    Menu,
    Bread
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .home {
    width: 100%;
    height: 100%;
    .content {
      position: absolute;
      width: 100%;
      top: 60px;
      bottom: 0;
      background-color: rgba(249,250,251, 0.338);
      // .el-main {
      //   background-color: rgba(242, 10, 10, 0.596);
      // }
    }
    .cont {
      width: 100%;
      height: 100%;
        margin: 0 0;
      }
  }
</style>
